import React from "react";
import Meta from "components/Meta";

function FallbackPage() {
  return (
    <>
      <Meta title="404" description="Page not found" />
      <section className="flex-1">
        <div className="overflow-hidden bg-white pt-16 px-4 sm:px-6 lg:px-8 xl:pt-20">
          <div className="mx-auto max-w-max lg:max-w-7xl lg:px-8 mt-44 mb-72">
            <p className="mt-2 sm:mt-4 text-4xl font-light sm:leading-10 tracking-tight text-neutral-600 sm:text-5xl">
              Page not found
            </p>
            <p className="mt-5 sm:mt-6 leading-normal max-w-lg text-neutral-400 sm:text-base">
              We're sorry, we either couldn't find the page you were looking
              for, or you don't have access to this resource. Please contact
              support if you believe this is an error.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default FallbackPage;
